import React from 'react';
import styled from 'styled-components';
import { solutionCards } from './constant';

const Container = styled.div`
    background: #fff;
`;

const SolutionContainer = styled.div`
    max-width: 1280px;
    margin: 0 auto;
    padding: 44px 20px 84px;

    @media screen and (max-width: 800px) {
        padding: 44px 20px;
    }
`;

const Heading = styled.h1`
    font-size: 42px;
    font-weight: 500;
    line-height: 48px;
    text-align: center;
    color: #262626;

    @media screen and (max-width: 800px) {
        font-size: 32px;
        line-height: 36px;
    }

    @media screen and (max-width: 520px) {
        font-size: 24px;
        line-height: 28.44px;
    }
`;

const ResourceGrid = styled.div`
    margin-top: 72px;
    display: grid;
    grid-template-columns: repeat(2, minmax(300px, 1fr));
    gap: 32px;

    @media screen and (max-width: 800px) {
        margin-top: 44px;
    }

    @media screen and (max-width: 700px) {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    @media screen and (max-width: 520px) {
        margin-top: 24px;
    }
`;

const Card = styled.a`
    border: 1px solid #e8e8e8;
    border-radius: 16px;
    padding: 16px;
    text-decoration: none;
    cursor: pointer;
`;

const CardBanner = styled.div`
    background-image: url(${({ backgroundImg }) => backgroundImg});
    background-size: cover;
    background-position: top;
    padding: 13px 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

const HeroImg = styled.img`
    cursor: pointer;

    @media screen and (max-width: 900px) {
        height: 105px;
        width: auto;
    }

    &:hover {
        transform: scale(1.15);
        transition: all 0.5s ease-in;
    }
`;

const CardHeading = styled.h3`
    font-size: 24px;
    font-weight: 500;
    line-height: 28.44px;
    color: #333;
    margin-top: 12px;

    @media screen and (max-width: 800px) {
        font-size: 14px;
        line-height: 18px;
    }
`;

const CardDesc = styled.p`
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #52525b;
    margin-top: 12px;

    @media screen and (max-width: 800px) {
        font-size: 12px;
        line-height: 16px;
    }

    @media screen and (max-width: 520px) {
        font-size: 14px;
        line-height: 18px;
    }
`;

function Solutions() {
    return (
        <Container>
            <SolutionContainer>
                <Heading>Solutions</Heading>
                <ResourceGrid>
                    {solutionCards.map((card) => (
                        <Card key={card.id} href={card.link.route} {...card.link}>
                            <CardBanner backgroundImg={card.background}>
                                <HeroImg src={card.img} alt="Illustration" />
                            </CardBanner>
                            <CardHeading>{card.title}</CardHeading>
                            <CardDesc>{card.desc}</CardDesc>
                        </Card>
                    ))}
                </ResourceGrid>
            </SolutionContainer>
        </Container>
    );
}

export default Solutions;
